import React from "react";

import { Scene, InfoCard, InstallForm } from "../../layout/shopify";

const Install = () => (
  <Scene>
    <InfoCard
      title="Payment integration made easy"
      description="Simply enter your Shopify url to begin installing Latitude's plugin."
    >
      <InstallForm />
    </InfoCard>
  </Scene>
);

export default Install;
